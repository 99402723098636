<template>
  <div class="groupBuyingGoods">
    <div class="basicInfo">
      <div class="title">基础信息</div>
      <div class="flexbox mt20">
        <div class="goods">
          <div>商品封面</div>
          <img class="goodsPath" :src="form.goodsPath" alt="" v-if="form.goodsPath">
          <div class="addGoodsPath flexbox flex-align-center flex-justify-center" v-else>
            <i class="el-icon-plus"></i>
          </div>
          <el-upload action="#" :disabled="disabled" :http-request="img_ad_upload" :show-file-list="false">
            <el-button type="primary" class="uploadBtn" :disabled="disabled">选择图片</el-button>
            <div slot="tip" class="tips">参考尺寸：750px x 700px</div>
          </el-upload>
        </div>
        <div class="formBox">
          <div class="flexbox flex-align-center">
            <span class="label">归属商户</span>
            <el-select :popper-append-to-body="false" v-model="form.shopId" style="width: 300px;margin: 0px 10px;" filterable remote
              placeholder="输入商户账号或名称" :disabled="disabled" :remote-method="getShopInfoSelect">
              <el-option v-for="item in shopOptions" :key="item.shopId" :label="item.shopName" :value="item.shopId">
              </el-option>
            </el-select>
          </div>
          <div class="flexbox flex-align-center mt30">
            <span class="label">团购商品标题</span>
            <el-input v-model="form.goodsName" style="width: 300px;margin: 0px 10px;" placeholder="输入团购商品标题" :disabled="disabled"
              :maxlength="30" show-word-limit></el-input>
          </div>
          <div class="flexbox flex-align-center mt30">
            <span class="label">分类</span>
            <el-select :popper-append-to-body="false" v-model="form.groupCatId" style="width: 300px;margin: 0px 10px;" clearable
              placeholder="选择分类" :disabled="disabled">
              <el-option v-for="item in catOptions" :key="item.groupCatId" :label="item.groupCatName" :value="item.groupCatId">
              </el-option>
            </el-select>
          </div>
          <div class="tips">注：此分类只能在你构建的首页已选分类中选择，如无法选择，请查看您是否完成首页构建</div>
          <div class="flexbox flex-align-center mt30">
            <span class="label">商品描述</span>
            <el-input type="textarea" v-model="form.remark" style="width: 300px;margin: 0px 10px;" placeholder="商品描述" :disabled="disabled"
              :maxlength="50" show-word-limit></el-input>
          </div>
          <div class="flexbox flex-align-center mt30">
            <span class="label">商品原价</span>
            <el-input v-model="form.price" type="number" style="width: 300px;margin: 0px 10px;" placeholder="商品原价" :disabled="disabled">
            </el-input>
            <span>元</span>
          </div>
          <div class="flexbox flex-align-center mt30">
            <span class="label">团购价</span>
            <el-input v-model="form.groupPrice" type="number" style="width: 300px;margin: 0px 10px;" placeholder="团购价" :disabled="disabled">
            </el-input>
            <span>元</span>
          </div>
          <div class="flexbox flex-align-center mt30">
            <span class="label">商户结算价</span>
            <el-input v-model="form.purchasePrice" type="number" style="width: 300px;margin: 0px 10px;" placeholder="成本价"
              :disabled="disabled">
            </el-input>
            <span>元</span>
          </div>
        </div>
        <div class="formBox">
          <div class="flexbox flex-align-center">
            <span class="label">是否推荐</span>
            <el-checkbox v-model="form.favorable" style="margin: 0px 10px;" :disabled="disabled">推荐</el-checkbox>
          </div>
          <div class="flexbox flex-align-center mt30">
            <span class="label">库存规则</span>
            <div v-if="selectedType==1||(selectedType==2&&unRelease)">
              <el-radio v-model="form.isForceStock" :label="1" style="margin: 0px 10px;" :disabled="disabled">不限库存</el-radio>
              <el-radio v-model="form.isForceStock" :label="2" :disabled="disabled">限制库存</el-radio>
            </div>
            <div v-else>
              <span v-if="form.isForceStock==1" style="margin: 0px 10px;">不限库存</span>
              <span v-else style="margin: 0px 10px;">限制库存</span>
            </div>
          </div>
          <div class="flexbox flex-align-center mt30" v-if="form.isForceStock === 2">
            <span class="label">库存量</span>
            <el-input v-model="form.stockCount" type="number" style="width: 300px;margin: 0px 10px;" placeholder="请输入库存量"
              :disabled="selectedType!=1&&!unRelease"></el-input>
          </div>
          <div class="flexbox flex-align-center mt30">
            <span class="label">团购有效期</span>
            <el-radio v-model="form.groupDuration" :label="1" style="margin: 0px 10px;" :disabled="disabled">一个月</el-radio>
            <el-radio v-model="form.groupDuration" :label="2" :disabled="disabled">三个月</el-radio>
            <el-radio v-model="form.groupDuration" :label="3" :disabled="disabled">半年</el-radio>
            <el-radio v-model="form.groupDuration" :label="4" :disabled="disabled">一年</el-radio>
            <el-radio v-model="form.groupDuration" :label="5" :disabled="disabled">永久</el-radio>
          </div>
          <div class="tips">注：此为用户团购商品截止时间（下单到失效）</div>
          <div class="flexbox flex-align-center mt30">
            <span class="label">限购规则</span>
            <el-radio v-model="form.isStock" :label="1" style="margin: 0px 10px;" :disabled="disabled">不限购</el-radio>
            <el-radio v-model="form.isStock" :label="2" :disabled="disabled">限购</el-radio>
          </div>
          <div class="flexbox flex-align-center mt30" v-if="form.isStock === 2">
            <span class="label">每人限购</span>
            <el-input v-model="form.personPerLimit" style="width: 300px;margin: 0px 10px;" placeholder="请输入每人限购" :disabled="disabled"
              type="number"></el-input>
            <span>份</span>
          </div>
        </div>
      </div>
    </div>
    <div class="goodsDetail">
      <div class="title">
        <span>商品详情</span>
        <span>注：可以直接上传多张长图或编辑文本，长图建议宽度为375px</span>
      </div>
      <div class="mt20">
        <TEditor class="editor" type="complete" v-model="form.goodsDetail" v-if="flag" />
      </div>
      <div class="btnBox flexbox flex-align-center flex-justify-center" v-if="!disabled">
        <el-button class="btn" type="primary" @click="openPreviewDialog">预览</el-button>

        <el-button class="btn" type="primary" @click="openSubmitDialog">保存并发布</el-button>
        <PrevReclick>
          <el-button class="btn" type="info" @click="save">只保存不发布</el-button>
        </PrevReclick>
        <el-button class="btn" type="info" @click="$router.go(-1)">取消</el-button>
      </div>
    </div>
    <div v-if="selectedType==3" class="apply-result goodsDetail">
      <p v-if="form.applyState===0">审核结果：未提交审核&emsp;{{form.auditTimeStr}}</p>
      <p v-if="form.applyState===10">审核结果：审核中&emsp;{{form.auditTimeStr}}</p>
      <p v-if="form.applyState===20">审核结果：审核失败&emsp;{{form.auditTimeStr}}</p>
      <p v-if="form.applyState===30">审核结果：审核通过&emsp;{{form.auditTimeStr}}</p>
      <!-- eslint-disable-next-line vue/valid-v-if -->
      <p v-if="form.rejectReason && form.applyState !==30" style="color:#f63d29;margin-top:10px;">失败原因：{{form.rejectReason}}</p>
    </div>
    <!-- 保存并发布提示弹窗 -->
    <Dialog ref="dialog" dialogWidth="500px">
      <div class="dialogBtn flexbox flex-align-center flex-justify-center">
        <PrevReclick>
          <el-button class="btn" type="primary" @click="submit">确认</el-button>
        </PrevReclick>
        <el-button class="btn" type="info" @click="$refs.dialog.showDialog = false">放弃</el-button>
      </div>
    </Dialog>
    <!-- 发布结果提示弹窗 -->
    <Dialog ref="resultDialog" dialogWidth="500px" @doneBtn="doneBtn"></Dialog>
    <!-- 预览 -->
    <Preview :form="form" :shopAddress="shopAddress" :shopName="shopName" ref="previewDialog"></Preview>
  </div>
</template>
<script>
import TEditor from '@/components/common/TEditor.vue';
import Dialog from '@/components/common/dialog.vue';
import FileUploader from '@base/utils/file/FileUploader';
import {
  $sg_getShopInfoSelect, $sg_getMchGroupCatSelect, $sp_createGroupBuying,
  $sg_getMchGroupGoodsDetail,
  $sp_upDateGroupBuying,

} from '@/api/groupBuying';
import Preview from './components/Preview.vue';

export default {
  data() {
    return {
      selectedType: '', // 1 新增，2编辑，3详情
      shopName: '', // 商店名称
      shopAddress: '', // 商店地址
      flag: false,
      disabled: false,
      unRelease: false,
      form: {
        groupGoodsId: '',
        shopId: '',
        groupCatId: '',
        goodsName: '',
        price: '',
        groupPrice: '',
        purchasePrice: '',
        goodsPath: '',
        stockCount: '',
        isForceStock: 2,
        favorable: false,
        remark: '',
        groupDuration: 1,
        isStock: 2,
        personPerLimit: '',
        goodsDetail: '',
      },
      shopOptions: [],
      catOptions: [],
      code: '', // 发布结果成功还是失败
      timerId: '', // 定时器id
    };
  },
  components: {
    TEditor,
    Dialog,
    Preview,
  },
  created() {
    console.log(this.$route.query);
    this.getMchGroupCatSelect();
    // type: 类型， 1-新增，2-编辑，3-详情
    const type = this.$route.query.type;
    this.selectedType = type;
    console.log(this.selectedType);
    if (type === '1') {
      this.flag = true;
    } else if (type === '2') {
      this.form.groupGoodsId = this.$route.query.id;
      this.getMchGroupGoodsDetail();
      if (this.$route.query.unRelease) {
        this.unRelease = this.$route.query.unRelease;
      }
    } else if (type === '3') {
      this.form.groupGoodsId = this.$route.query.id;
      this.getMchGroupGoodsDetail();
      this.disabled = true;
    }
  },
  methods: {
    // 获取归属商户下拉列表
    getShopInfoSelect(query) {
      if (!query) return;
      const params = {
        keyword: query,
      };
      $sg_getShopInfoSelect(params).then((res) => {
        this.shopOptions = res;
        console.log('这是归属商户下拉', res);
      });
    },
    // 获取团购分类下拉列表
    getMchGroupCatSelect() {
      $sg_getMchGroupCatSelect().then((res) => {
        this.catOptions = res;
        console.log('这是下拉分类', res);
      });
    },
    // 获取团购商品详情
    getMchGroupGoodsDetail() {
      const params = {
        groupGoodsId: this.form.groupGoodsId,
      };
      $sg_getMchGroupGoodsDetail(params).then((res) => {
        this.form = res;
        this.shopName = res.shopName;
        this.shopAddress = res.address;
        this.$nextTick(() => {
          this.form.goodsDetail = res.goodsDetail;
        });
        this.form.favorable = res.favorable === 2;
        this.shopOptions = [{
          shopName: res.shopName,
          shopId: res.shopId,
        }];
        this.flag = true;
      });
    },
    // 上传商品图片
    async img_ad_upload(file) {
      const arr = file.file.name.split('.');
      const fileUploder = new FileUploader(file.file, { addLibrary: false, compress: true, ext: arr[arr.length - 1] });
      fileUploder.upload().then((url) => {
        this.form.goodsPath = url;
      }).catch((error) => {
        this.$message.error(error.message);
      });
    },
    // 表单校验
    async checkForm() {
      // eslint-disable-next-line operator-assignment
      this.form.purchasePrice = this.form.purchasePrice * 1;
      await this.$validator({
        ...this.form,
      }, [
        'goodsPath/require/商品封面',
        'shopId/require/归属商户',
        'goodsName/require/商品标题',
        'remark/require/商品描述',
        'groupCatId/require/分类',
        'price/require/商品原价',
        'purchasePrice/require/商品结算价',
        'groupPrice/require/团购价',
        'goodsDetail/require/商品详情',
      ]);
      if (this.form.price < 0.01 || this.form.price > 9999999) {
        this.$message.error('商品原价最小为0.01，最大为9999999');
        return false;
      }
      if (this.form.groupPrice < 0.01 || this.form.groupPrice > 9999999) {
        this.$message.error('商品团购价最小为0.01，最大为9999999');
        return false;
      }
      if (this.form.purchasePrice < 0.01 || this.form.groupPrice > 9999999) {
        this.$message.error('商品结算价最小为0.01，最大为9999999');
        return false;
      }
      if (this.form.isForceStock === 2 && !this.form.stockCount) {
        this.$message.error('库存量不能为空');
        return false;
      }
      if (this.form.isForceStock === 2 && (this.form.stockCount < 1 || this.form.stockCount > 999999)) {
        this.$message.error('库存量最小输入1，最大输入999999');
        return false;
      }
      if (this.form.isForceStock === 2 && this.form.stockCount % 1 !== 0) {
        this.$message.error('库存量必须为整数');
        return false;
      }
      if (this.form.isStock === 2 && !this.form.personPerLimit) {
        this.$message.error('每人限购不能为空');
        return false;
      }
      if (this.form.isStock === 2 && (this.form.personPerLimit < 1 || this.form.personPerLimit > 9999999)) {
        this.$message.error('每人限购最小输入1，最大输入9999999');
        return false;
      }
      if (this.form.isStock === 2 && this.form.personPerLimit % 1 !== 0) {
        this.$message.error('每人限购必须为整数');
        return false;
      }
      if (this.form.price * 1 <= this.form.groupPrice * 1 && this.form.price * 1 <= this.form.purchasePrice * 1) {
        this.$message.error('商品原价不能小于团购价与结算价');
        return false;
      }
      if (this.form.groupPrice * 1 >= this.form.price * 1) {
        console.log(this.form.groupPrice, this.form.price, '1111111111111', this.form.groupPrice * 1 >= this.form.price * 1);
        this.$message.error('团购价不能大于原价');
        return false;
      }
      if (this.form.purchasePrice * 1 >= this.form.groupPrice * 1) {
        this.$message.error('结算价不能大于团购价');
        return false;
      }
      return true;
    },
    // 打开保存并发布提示弹窗
    async openSubmitDialog() {
      // 校验表单输入数据
      if (!await this.checkForm()) return;
      this.$refs.dialog.dialogs = {
        icon: '/img/dialog/exploreIcon.png',
        title: '确定发布上线',
        content: '成功发布后，平台将尽快发起审核，审核通过后即可进行上下架操作',
      };
      this.$refs.dialog.showDialog = true;
    },
    // 保存并发布
    submit() {
      this.$refs.dialog.showDialog = false;
      const type = this.$route.query.type;
      const form = JSON.parse(JSON.stringify(this.form));
      form.favorable = this.form.favorable ? 2 : 1;
      const params = {
        ...form,
        state: 2,
      };
      const loading = this.$loading({
        lock: true,
        text: '正在发布',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      // 判断是新增还是编辑，新增用create，编辑用upDate
      const methods = ['', $sp_createGroupBuying, $sp_upDateGroupBuying];

      // if (type === 1) {
      //   methodsType = 0;
      // } else if (type === 2) {
      //   methodsType = 1;
      // }
      methods[type](params).then((res) => {
        loading.close();
        this.code = res.code;
        if (this.code === 1000) {
          // 发布成功
          this.$refs.resultDialog.dialogs = {
            icon: '/img/dialog/icon-success.png',
            title: '发布成功!',
            done: '好的',
          };
          this.$refs.resultDialog.showDialog = true;
          this.timerId = setTimeout(() => {
            this.$router.go(-1);
            this.$refs.resultDialog.showDialog = false;
          }, 3000);
        } else {
          // 发布失败
          this.$refs.resultDialog.dialogs = {
            icon: '/img/dialog/icon-fail.png',
            title: '发布失败!',
            done: '重新发布',
          };
          this.$refs.resultDialog.showDialog = true;
          this.timerId = setTimeout(() => {
            this.$refs.resultDialog.showDialog = false;
          }, 3000);
        }
      }).catch(() => {
        console.log('发布失败');
        // 发布失败
        this.$refs.resultDialog.dialogs = {
          icon: '/img/dialog/icon-fail.png',
          title: '发布失败!',
          done: '重新发布',
        };
        this.$refs.resultDialog.showDialog = true;
        this.timerId = setTimeout(() => {
          this.$refs.resultDialog.showDialog = false;
        }, 3000);
      }).finally(() => {
        loading.close();
      });
    },
    // 发布结果弹窗按钮点击事件
    doneBtn() {
      clearTimeout(this.timerId);
      if (this.code === 1000) {
        // 发布成功
        this.$router.go(-1);
      } else {
        // 发布失败
        this.$refs.resultDialog.showDialog = false;
        this.submit();
      }
    },
    // 只保存不发布
    async save() {
      // 校验表单输入数据
      if (!await this.checkForm()) return;
      const form = JSON.parse(JSON.stringify(this.form));
      form.favorable = this.form.favorable ? 2 : 1;
      const params = {
        ...form,
        state: 1,
      };
      console.log(params);
      if (!this.form.groupGoodsId) {
        $sp_createGroupBuying(params).then(() => {
          this.$router.go(-1);
        });
      } else {
        $sp_upDateGroupBuying(params).then(() => {
          this.$router.go(-1);
        });
      }
    },
    // 打开预览弹窗
    openPreviewDialog() {
      if (!this.shopAddress && this.shopOptions.length !== 0) {
        const index = this.shopOptions.findIndex((v) => v.shopId === this.form.shopId);
        this.shopAddress = this.shopOptions[index].address;
        this.shopName = this.shopOptions[index].shopName;
        console.log(this.shopOptions, this.shopName);
      }
      this.$refs.previewDialog.openDialog();
    },
  },
};
</script>
<style lang="scss">
.groupBuyingGoods {
  width: 100%;
  .basicInfo {
    width: 100%;
    border-radius: 12px;
    padding: 20px;
    box-sizing: border-box;
    color: #666;
    background-color: #fff;
    overflow: auto;
    .title {
      font-size: 15px;
      font-weight: bold;
    }
    .goods {
      width: 240px;
      font-size: 14px;
      .goodsPath {
        width: 170px;
        height: 170px;
        border-radius: 8px;
        margin-top: 10px;
      }
      .addGoodsPath {
        width: 170px;
        height: 170px;
        border-radius: 8px;
        margin-top: 10px;
        border: 1px dashed #999;
        background-color: #f5f5f5;
        font-size: 50px;
        color: #999;
      }
      .uploadBtn {
        margin-left: 40px;
        margin-top: 20px;
      }
      .tips {
        margin-left: 10px;
        margin-top: 10px;
      }
    }
    .formBox {
      margin-left: 40px;
      .label {
        width: 100px;
        text-align: right;
      }
      .tips {
        width: 350px;
        margin-left: 90px;
        font-size: 12px;
        color: #f63d29;
        margin-top: 10px;
      }
      .el-checkbox__inner {
        border-radius: 50%;
      }
    }
  }
  .goodsDetail {
    width: 100%;
    border-radius: 12px;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 20px;
    .title span:first-child {
      font-size: 15px;
      font-weight: bold;
    }
    .title span:last-child {
      font-size: 12px;
      color: #ef3f46;
      margin-left: 10px;
    }
    .btnBox {
      width: 100%;
      margin-top: 20px;
      .btn {
        width: 290px;
        height: 60px;
        border-radius: 60px;
      }
    }
  }
  .dialogBtn {
    width: 100%;
    margin-top: 60px;
    .btn {
      width: 200px;
      height: 50px;
      border-radius: 50px;
    }
  }
  .apply-result {
    p {
      font-size: 16px;
      color: #333;
      font-weight: bold;
    }
  }
}
</style>
