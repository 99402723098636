<template>
  <div class="tinymce-editor-continaer">
    <div :id="editDomId" class="content-target-t"></div>
  </div>
</template>
<script>
// import FileUpload from '@/utils/file_upload';


import FileUploader from '@base/utils/file/FileUploader';
import { loadTEditor } from '@base/utils/map/loadSdkScript';

export default {
  name: 'TEditor',
  components: {
  },
  data() {
    return {
      editDomId: `tEditor${new Date().getTime()}`,
      initing: false,
    };
  },
  props: {
    value: String,
    type: String,
  },
  watch: {
    value: {
      immediate: true,
      handler(val, oldVal) {
        console.log('value changeed', this.edInstance);
        if (oldVal !== val) {
          // this.inintEditor();
        }
      },
    },
  },
  methods: {
    inintEditor() {
      if (this.edInstance) {
        if (this.value) {
          this.edInstance.setContent(this.value);
        }
        return;
      }
      if (this.initing) return;
      const self = this;
      this.initing = true;
      let toolbar;
      let plugin;
      switch (this.type) {
        case 'complete':
          toolbar = [
            'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | preview | image | fullscreen',
            'formatselect | fontsizeselect | outdent | indent | pagebreak',
          ];
          plugin = ['image', 'fullscreen', 'preview', 'paste'];
          break;
        default:
          toolbar = [
            'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | preview | fullscreen',
            'formatselect | fontsizeselect | outdent | indent | pagebreak',
          ];
          plugin = ['fullscreen', 'preview'];
      }
      loadTEditor().then((tinymce) => {
        const tinymceEditor = tinymce.init({
          selector: `#${this.editDomId}`,
          language: 'zh_CN',
          plugins: plugin,
          toolbar,
          paste_data_images: this.type === 'complete',
          images_upload_handler(blobInfo, succFun, failFun) {
            const file = blobInfo.blob();
            console.log(file, succFun, failFun);
            const upload = new FileUploader(file, { addLibrary: false, compress: true });
            upload.upload().then((url) => {
              const imgSrc = self.$imgPrefix(url);
              succFun(imgSrc);
            }).catch((error) => {
              this.$message.error(error.message);
            });
          },
          setup(ed) {
            ed.on('change', () => {
              self.$emit('input', ed.getContent());
            });
          },
        });
        console.log('tinymceEditor', tinymceEditor);
        tinymceEditor.then((resp) => {
          console.log('tinymceEditor', tinymceEditor, resp, this.value);
          const we = resp[0];
          this.edInstance = we;
          if (this.value) {
            we.setContent(this.value);
          }
        }).finally((err) => {
          console.log(err);
        });
      });
    },
  },
  mounted() {
    this.inintEditor();
  },
  beforeDestroy() {
    if (this.edInstance) {
      this.edInstance.destroy();
      this.edInstance = null;
    }
  },
};
</script>
<style lang="scss">
.tinymce-editor-continaer {
  min-width: 500px;
  .content-target-t {
    min-height: 600px;
  }
  .tox-statusbar__branding {
    display: none;
  }
}
</style>
