<template>
  <div class="groupBuyingList">
    <div class="flexbox flex-lr flex-align-center">
      <el-input v-model="form.goodsName" placeholder="团购名称" style="width:200px;" clearable></el-input>
      <el-input v-model="form.shopName" placeholder="商户名称" style="width:200px;margin-left:20px;" clearable></el-input>
      <el-input v-model="form.shopAccount" placeholder="商户账号" style="width:200px;margin-left:20px;" clearable></el-input>
      <el-select v-model="form.goodsState" class="ml20 mr20" clearable placeholder="商品状态" style="width:150px;">
        <el-option v-for="item in stateOptions" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-button type="primary" @click="search">筛选</el-button>
      <el-button type="info" @click="clear">清空</el-button>
      <el-button type="primary" @click="addGoods('1')">新增团购<i class="el-icon-circle-plus-outline"></i></el-button>
    </div>
    <div class="tips">注：请在团购中查询团购订单，分润中查看团购分润</div>
    <div class="tableBox">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="groupGoodsId" label="团购编号" align="center"></el-table-column>
        <el-table-column prop="shopName" label="商户" align="center"></el-table-column>
        <el-table-column prop="goodsName" label="团购名称" align="center"></el-table-column>
        <el-table-column prop="groupCatName" label="团购分类" align="center"></el-table-column>
        <el-table-column prop="groupPrice" label="团购价格（元）" align="center"></el-table-column>
        <el-table-column prop="saleCount" label="销量（份）" align="center">
          <template slot-scope="scope">{{scope.row.saleCount || '-'}}</template>
        </el-table-column>
        <el-table-column prop="surplusStockCount" label="剩余库存" align="center">
          <template slot-scope="scope">{{scope.row.surplusStockCount || '-'}}</template>
        </el-table-column>
        <el-table-column prop="reviewNum" label="已核销" align="center">
          <template slot-scope="scope">{{scope.row.reviewNum || '-'}}</template>
        </el-table-column>
        <el-table-column prop="expiredNum" label="已过期" align="center">
          <template slot-scope="scope">{{scope.row.expiredNum || '-'}}</template>
        </el-table-column>
        <el-table-column prop="applyState" label="审核状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.applyState===10">审核中</span>
            <span v-if="scope.row.applyState===20">审核失败</span>
            <span v-if="scope.row.applyState===30">审核通过</span>
            <span v-if="scope.row.applyState===0">未发布</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="团购状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.applyState===20">-</span>
            <span v-if="scope.row.state === 1&&scope.row.applyState!==20">未上架</span>
            <span v-if="scope.row.applyState===30">
              <span v-if="scope.row.state === 2">进行中</span>
              <span v-if="scope.row.state === 3">结束</span>
              <span v-if="scope.row.state === 4">暂停</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="上架/下架" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.applyState===30">
              <el-button type="text" v-if="scope.row.state === 1" @click="updateState(scope.row.groupGoodsId, 2)">上架</el-button>
              <el-button type="text" v-if="scope.row.state === 2" @click="updateState(scope.row.groupGoodsId, 3)">下架</el-button>
              <span v-if="scope.row.state === 3">-</span>
              <el-button type="text" v-if="scope.row.state === 4" @click="updateState(scope.row.groupGoodsId, 2)">上架</el-button>
              <!-- <span v-if="scope.row.state === 4">-</span> -->
            </span>
            <span v-if="scope.row.applyState!==30">-</span>
          </template>
        </el-table-column>
        <el-table-column prop=" name" label="操作" align="center">
          <template slot-scope="scope">
            <!-- <el-button type="text" v-if="scope.row.state === 1" 
            @click="addGoods('2', scope.row.groupGoodsId,scope.row)">编辑</el-button> -->
            <el-button type="text" @click="addGoods('2', scope.row.groupGoodsId,scope.row)">编辑</el-button>
            <el-button type="text" @click="addGoods('3', scope.row.groupGoodsId,scope.row)">详情</el-button>
            <el-button type="text" @click="delect(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination class="mt10" background :page-count="totalPage" :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>
    <!-- 删除弹窗 -->
    <Dialog ref="delectDialog" dialogWidth="500px">
      <div v-if="!isBuying" class="dialogBtn flexbox flex-align-center flex-justify-center">
        <el-button class="btn" type="primary" @click="confirmDelect">确认</el-button>
        <el-button class="btn" type="info" @click="$refs.delectDialog.showDialog = false">取消</el-button>
      </div>
      <div v-else class="dialogBtn flexbox flex-align-center flex-justify-center">
        <el-button class="btn" type="primary" @click="$refs.delectDialog.showDialog = false">好的</el-button>
      </div>
    </Dialog>
  </div>
</template>
<script>
import {
  $sg_queryMchGroupGoods, $sp_updateState, $sg_deleteGroupGoods, $sg_checkUsed,
} from '@/api/groupBuying';
import Dialog from '@/components/common/dialog.vue';

export default {
  components: {
    Dialog,
  },
  data() {
    return {
      content: '',
      isBuying: false, // 是否有销量
      delectGroupId: '', // 要删除的团购id
      form: {
        goodsName: '',
        shopName: '',
        shopAccount: '',
        goodsState: '',
      },
      time: '',
      totalPage: 1,
      currentPage: 1,
      tableData: [],
      stateOptions: [
        {
          value: 1,
          label: '未上架',
        },
        {
          value: 2,
          label: '进行中',
        },
        {
          value: 3,
          label: '结束',
        },
      ],
    };
  },
  created() {
    this.getTableData();
  },
  methods: {
    // 筛选
    search() {
      this.currentPage = 1;
      this.getTableData();
    },
    // 清空
    clear() {
      this.form = {
        goodsName: '',
        shopName: '',
        shopAccount: '',
        goodsState: '',
      };
      this.currentPage = 1;
      this.getTableData();
    },
    // 请求列表数据
    getTableData() {
      const params = {
        pageCurrent: this.currentPage,
        pageSize: 15,
        ...this.form,
      };
      $sg_queryMchGroupGoods(params).then((res) => {
        this.tableData = res.records;
        this.totalPage = res.pages;
      });
    },
    // 更新团购商品上下架状态
    updateState(groupGoodsId, state) {
      const tips = state === 2 ? '上架' : '下架';
      this.$confirm(`确定要${tips}吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        showClose: false,
        closeOnClickModal: false,
      }).then(() => {
        const params = {
          groupGoodsId,
          state,
        };
        $sp_updateState(params).then(() => {
          this.getTableData();
        });
      }).catch(() => { });
    },
    // 跳转到新增商品页面
    addGoods(type, id, row) {
      // type: 类型， 1-新增，2-编辑，3-详情
      console.log(row);
      let unRelease = false;
      if (type === '2' && row.applyState === 0) {
        unRelease = true;
      }
      this.$router.push({
        name: 'GroupBuyingGoods',
        query: {
          id,
          type,
          unRelease,
        },
      });
    },
    // 删除
    async delect(row) {
      this.delectGroupId = row.groupGoodsId;
      // 发送请求 查询是否有销量
      const res = await $sg_checkUsed({ groupGoodsId: row.groupGoodsId });
      this.isBuying = res;
      this.$refs.delectDialog.showDialog = true;
      console.log(this.isBuying);
      if (!this.isBuying) {
        this.content = '删除此团购活动后将无法恢复，确认要删除吗？';
      } else {
        this.content = '该团购下已有购买数据，不可删除！';
      }
      console.log(this.content);
      this.$refs.delectDialog.dialogs = {
        icon: '/img/dialog/exploreIcon.png',
        title: '提示',
        content: this.content,
      };
    },
    // 点击删除弹窗事件
    confirmDelect() {
      $sg_deleteGroupGoods({
        groupGoodsId: this.delectGroupId,
      }).then((res) => {
        console.log(res);
        this.$message.success('删除成功');
        this.getTableData();
      }).catch((err) => {
        console.log(err);
        if (err.code !== 1000) {
          this.$message.error(err.subMsg);
        }
      });
      this.$refs.delectDialog.showDialog = false;
    },
    // 翻页
    pageChange() {
      this.getTableData();
    },
  },
};
</script>
<style lang="scss" scoped>
.groupBuyingList {
  width: 100%;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .tips {
    width: 100%;
    margin-top: 40px;
    font-size: 14px;
    color: #666;
  }
  .tableBox {
    width: 100%;
    margin-top: 10px;
  }
  .el-table {
    min-height: 580px;
    & > th {
      background-color: #ef3f46;
      color: #fff;
    }
  }
  .dialogBtn {
    width: 100%;
    margin-top: 60px;
    .btn {
      width: 200px;
      height: 50px;
      border-radius: 50px;
    }
  }
}
</style>
